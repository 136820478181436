import * as I18nJs from 'i18n-js'
import translations from './translations.json'
import latvianLocale from './yup.locale.lv'
import englishLocale from './yup.locale.en'
import { setLocale } from 'yup'
import { formatDate } from 'utils'

const I18n = new I18nJs.I18n(translations)
I18n.defaultLocale = 'lv'
I18n.locale = document.documentElement.getAttribute('lang')
const t = I18n.t.bind(I18n)

if (I18n.locale == 'lv') {
  setLocale(latvianLocale)
} else if (I18n.locale == 'en') {
  setLocale(englishLocale)
}

setLocale({
  mixed: {
    required: t('errors.messages.blank'),
    notType: ({ path, type, value, originalValue }) => {
      return `${I18n.t('errors.messages.invalid')}`
    },
  },
  number: {
    min: ({ min }) => I18n.t('errors.messages.greater_than', { count: min }),
  },
  date: {
    min: ({ min }) => I18n.t('errors.messages.greater_than', { count: formatDate(min) }),
    max: ({ max }) => I18n.t('errors.messages.less_than', { count: formatDate(max) }),
  },
})

export default I18n
export { t }
