import snakeCase from 'lodash/snakeCase'

export default function toSortInstruction({
  sortDirection,
  sortField,
}: {
  sortDirection?: string
  sortField?: string
}) {
  const dir = sortDirection?.toLowerCase() === 'asc' ? 'asc' : 'desc'
  return sortField ? `${snakeCase(sortField)} ${dir}` : null
}
