// https://github.com/lodash/lodash/issues/1379

import each from 'lodash/each'

export default function chunkBy(collection, predicate, context = null) {
  var chunks = []
  var prevKey = null
  var chunkValues = []
  each(collection, function (value) {
    var key = predicate.apply(context, arguments)
    if (key == prevKey) {
      chunkValues.push(value)
    } else {
      // Guard against init values
      if (chunkValues.length) {
        chunks.push([prevKey, chunkValues])
      }
      prevKey = key
      chunkValues = [value]
    }
  })
  // Push hanging values
  if (chunkValues.length) {
    chunks.push([prevKey, chunkValues])
  }
  return chunks
}
