import format from 'date-fns/format'
import parseJSON from 'date-fns/parseJSON'
import parseISO from 'date-fns/parseISO'
import formatISO from 'date-fns/formatISO'
import { t } from 'i18n'

export enum DateFormat {
  UI = 'dd.MM.yy HH:mm',
  UI_FULL_DATETIME = 'dd.MM.yyyy HH:mm',
  UI_DATE = 'dd.MM.yy',
  UI_FULL_DATE = 'dd.MM.yyyy',
  UI_SHORT = 'dd.MM HH:mm',
  DATE = 'dd.MM',
  TIME = 'HH:mm',
  DB_TIME = 'yyyy-MM-dd HH:mm',
  FILE_NAME_SUFFIX = 'yyyy-MM-dd-HH-mm',
  DB = 'yyyy-MM-dd',
  DAYNAME = 'EEEE',
  ISO_WEEK_YEAR = 'RRRR-II',
  INPUT_DATETIME_LOCAL = "yyyy-MM-dd'T'HH:mm",
}

export const ensureDate = (maybeDate: string | Date): Date => {
  let date: Date

  if (maybeDate.constructor === Date) {
    date = maybeDate
  } else if (maybeDate.constructor === String) {
    date = maybeDate.includes('T') ? parseJSON(maybeDate) : parseISO(maybeDate)
  }

  return date
}

export const formatDate = (maybeDate: string | Date, outputFormat?: 'iso' | string): string => {
  try {
    const date = ensureDate(maybeDate)

    if (outputFormat === 'iso') {
      return formatISO(date)
    } else {
      return format(date, outputFormat || DateFormat.UI)
    }
  } catch (error) {
    return '-'
  }
}

export const weekDays = (): Array<String> => {
  return [1, 2, 3, 4, 5, 6, 0].map((_, i) => t('date.day_names')[i])
}
