export default {
  mixed: {
    default: 'is invalid',
    required: 'is a required field',
    oneOf: 'must be one of the following values: ${values}',
    notOneOf: 'must not be one of the following values: ${values}',
    defined: 'must be defined',
  },
  string: {
    length: 'must be exactly ${length} characters',
    min: 'must be at least ${min} characters',
    max: 'must be at most ${max} characters',
    matches: 'must match the following: "${regex}"',
    email: 'must be a valid email',
    url: 'must be a valid URL',
    uuid: 'must be a valid UUID',
    trim: 'must be a trimmed string',
    lowercase: 'must be a lowercase string',
    uppercase: 'must be a upper case string',
  },
  number: {
    min: 'must be greater than or equal to ${min}',
    max: 'must be less than or equal to ${max}',
    lessThan: 'must be less than ${less}',
    moreThan: 'must be greater than ${more}',
    positive: 'must be a positive number',
    negative: 'must be a negative number',
    integer: 'must be an integer',
  },
  date: {
    min: 'field must be later than ${min}',
    max: 'field must be at earlier than ${max}',
  },
  object: {
    noUnknown: 'field has unspecified keys: ${unknown}',
  },
  array: {
    min: 'field must have at least ${min} items',
    max: 'field must have less than or equal to ${max} items',
    length: 'must be have ${length} items',
  },
  boolean: {
    isValue: 'field must be ${value}',
  },
}
