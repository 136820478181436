import forOwn from 'lodash/forOwn'
import camelCase from 'lodash/camelCase'

export default function extractErrors(error: any) {
  if (error?.networkError?.result?.errors?.[0]) {
    const { message, messages, messages_nested, fullMessages } = error?.networkError?.result?.errors?.[0]
    const transformedMessages = {}
    const transformedMessagesNested = {}

    forOwn(messages, (value, key) => {
      transformedMessages[camelCase(key)] = value
    })

    if (messages_nested) {
      forOwn(messages_nested, (value, _key) => {
        const fullKey = `${camelCase(value.model)}.${camelCase(value.attributes.index)}.${camelCase(value.attribute)}`
        transformedMessagesNested[fullKey] = value.message
      })
    }

    return { message, messages: transformedMessages, messagesNested: transformedMessagesNested, fullMessages: fullMessages }
  } else {
    return { message: error.message }
  }
}
