import { createTheme } from '@mui/material/styles'

// import getMetaValue from './utils/getMetaValue'
// __webpack_nonce__ = getMetaValue('csp-nonce')

declare module '@mui/material/styles' {
  interface Palette {
    plannerLocked: Palette['primary']
    plannerError: Palette['primary']
    plannerCancelled: Palette['primary']
    plannerSuccess: Palette['primary']
    plannerWarning: Palette['primary']
    plannerRowBackground: Palette['primary']
    plannerHighlight: Palette['primary']
  }
  interface PaletteOptions {
    plannerLocked: PaletteOptions['primary']
    plannerError: PaletteOptions['primary']
    plannerCancelled: PaletteOptions['primary']
    plannerSuccess: PaletteOptions['primary']
    plannerWarning: PaletteOptions['primary']
    plannerRowBackground: PaletteOptions['primary']
    plannerHighlight: PaletteOptions['primary']
  }
}

declare module '@mui/material/styles/createPalette' {
  interface CommonColors {
    toolbarPrimary: string
  }
}

const DEFAULT_BACKGROUND = '#F8F8F8'

const theme = createTheme({
  palette: {
    background: { default: DEFAULT_BACKGROUND },
    common: {
      toolbarPrimary: '#393939',
    },
    plannerLocked: { main: '#f0fff5', light: '#f0fff5', dark: '#f0fff5' },
    plannerError: { main: '#ef5350', light: 'rgb(244, 144, 143)', dark: 'rgb(167, 58, 56)' },
    plannerCancelled: { main: '#ffE0D7', light: '#ffE0D7', dark: '#ff9090' },
    plannerSuccess: { main: '#4caf50', light: 'rgb(139, 203, 143)', dark: 'rgb(53, 122, 56)' },
    plannerWarning: { main: '#ff9800', light: 'rgb(255, 188, 91)', dark: 'rgb(178, 106, 0)' },
    plannerHighlight: { main: '#FFFFE0', light: '#FFFFE0', dark: '#FFFFE0' },
    error: { main: '#d65049', light: '#d65049', dark: '#d8342c'}
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          ':disabled': {
            backgroundColor: 'rgba(190,190,190,0.15)',
          },
          '&.MuiSelect-select.Mui-disabled': {
            backgroundColor: 'rgba(190,190,190,0.15)',
          },
          '&.MuiInputBase-input.Mui-disabled': {
            backgroundColor: 'rgba(190,190,190,0.15)',
          },
        },
      },
    },
    MuiLink: {
      defaultProps: { underline: 'hover' },
    },
    MuiListItemText: {
      styleOverrides: { root: { flex: '1 1' } },
    },
    MuiBreadcrumbs: {
      styleOverrides: { root: { margin: '8px 0 5px 0' } },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          margin: '16px 0 4px 0',
          fontWeight: 600,
          fontSize: '1.75rem',
        },
        h2: {
          margin: '16px 0 4px 0',
          fontWeight: 600,
          fontSize: '1.25rem',
        },
        h3: {
          margin: '16px 0 4px 0',
          fontWeight: 600,
          fontSize: '1rem',
        },
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
})

export default theme
