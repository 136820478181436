import { init } from '@sentry/browser'
import getMetaValue from 'utils/getMetaValue'

const ENVIRONMENTS = ['staging', 'production']

const env = getMetaValue('environment')

init({
  environment: env,
  enabled: ENVIRONMENTS.indexOf(env) > -1,
  dsn: getMetaValue('sentry-dsn'),
})
