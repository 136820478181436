export default {
  mixed: {
    default: 'nav derīgs',
    required: 'ir jāaizpilda',
    oneOf: 'ir jābūt vienai no šīm vērtībām: ${values}',
    notOneOf: 'nedrīkst būt viena no šīm vērtībām: ${values}',
    defined: 'ir jābūt definētai vērtībai',
  },
  string: {
    length: 'ir jābūt tieši ${length} simboliem',
    min: 'ir jābūt vismaz ${min} simboliem',
    max: 'ir jabūt ne vairāk kā ${max} simboliem',
    matches: 'ir jāatbilst definētajam formātam: "${regex}"',
    email: 'ir jābūt derīgam e-pastam',
    url: 'ir jābūt derīgam URL',
    uuid: 'ir jābūt derīgam UUID',
    trim: 'nedrīkst saturēt tukšumus teksta sākumā un beigās ',
    lowercase: 'ir jāsatur tikai mazie burti',
    uppercase: 'ir jāsatur tikai lielie burti',
  },
  number: {
    min: 'ir jābūt lielākam vai vienādam ar ${min}',
    max: 'ir jābūt mazākam vai vienādam ar ${max}',
    lessThan: 'ir jābūt mazākam par ${less}',
    moreThan: 'ir jābūt lielākam par ${more}',
    positive: 'ir jābūt pozitīvai vērtībai',
    negative: 'ir jābūt negatīvai vērtībai',
    integer: 'ir jābūt veselam skaitlim',
  },
  date: {
    min: 'ir jābūt pēc ${min}',
    max: 'ir jābūt pirms ${max}',
  },
  object: {
    noUnknown: 'satur nezināmas pazīmes: ${unknown}',
  },
  array: {
    min: 'ir jāsatur vismaz ${min} ieraksti',
    max: 'ir jāsatur ne vairāk kā ${max} ieraksti',
    length: 'ir jāsastāv no tieši ${length} ierakstiem',
  },
  boolean: {
    isValue: 'vērtībai ir jābūt ${value}',
  },
}
